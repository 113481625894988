import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import AppTopbar from "./AppTopbar"
import AppRightPanel from "./AppRightPanel"

import { Toast } from "primereact/toast"

//CSS
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "../styles/App.scss"
import api from "../services/api"

const App = ({ children }) => {
    let dataLocalStorage
    let toast = useRef(null)

    let menuClick
    let rightPanelClick
    let configClick
    let searchClick
    let topbarUserMenuClick

    let [resetActiveIndex, setResetActiveIndex] = useState(null)
    let [notifications, setNotifications] = useState([])
    let [profileUser, setProfileUser] = useState({})
    let [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false)
    let [sidebarStatic, setSidebarStatic] = useState(false)
    let [sidebarActive, setSidebarActive] = useState(false)
    let [menuActive, setMenuActive] = useState(false)
    let [menuMode] = useState("horizontal")
    let [configActive, setConfigActive] = useState(false)
    let [inputStyle] = useState("filled")
    let [ripple] = useState(false)
    let [rightPanelActive, setRightPanelActive] = useState(false)

    //Themes
    let [topbarScheme, setTopbarScheme] = useState("dark")
    let [menuScheme, setMenuScheme] = useState("dark")
    let [searchActive, setSearchActive] = useState(false)
    let [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false)

    const menu = [
        // {
        //     label: "Feimec 2024",
        //     icon: "pi pi-list",
        //     to: "/criacao",
        // },
        {
            label: "Oportunidades",
            icon: "pi pi-list",
            to: "/crm/oportunidades",
        },
        {
            label: "Oportunidades Simples",
            icon: "pi pi-list",
            to: "/crm/oportunidades_limpa",
        },

        {
            label: "Empresa/Clientes",
            icon: "pi pi-check-square",
            to: "/crm/empresa-cliente",
        },

        {
            label: "Contatos",
            icon: "pi pi-users",
            to: "/crm/contatos",
        },

        {
            label: "Tarefas",
            icon: "pi pi-clock",
            to: "/crm/tarefas",
        },

        {
            label: "Propostas",
            icon: "pi pi-id-card",
            to: "/crm/todas/propostas",
        },

        {
            label: "Configurações",
            icon: "pi pi-sliders-h",
            items: [
                {
                    label: "Atendimentos",
                    icon: "pi pi-whatsapp",
                    items: [
                        {
                            label: "Arquivos de Atendimento",
                            icon: "pi pi-paperclip",
                            to: "/crm/configuracoes/atendimento/anexos",
                        },

                        {
                            label: "Categorias de Atendimento",
                            icon: "pi pi-list",
                            to: "/crm/configuracoes/atendimento/grupos",
                        },

                        {
                            label: "Mensagens Padrões",
                            icon: "pi pi-comment",
                            to: "/crm/configuracoes/atendimento/mensagem-padrao",
                        },
                        {
                            label: "Roteiro de Mensagem",
                            icon: "pi pi-comments",
                            to: "/crm/configuracoes/atendimento/roteiro",
                        },
                        {
                            label: "Templates da Meta",
                            icon: "pi pi-facebook",
                            to: "/crm/configuracoes/atendimento/template",
                        },
                    ],
                },
                {
                    label: "Apresentações de Equipamentos",
                    icon: "pi pi-file-pdf",
                    to: "/crm/configuracoes/apresentacoes",
                },

                {
                    label: "Fontes de Chegada",
                    icon: "pi pi-briefcase",
                    to: "/crm/configuracoes/fontes",
                },

                {
                    label: "Interesses",
                    icon: "pi pi-tags",
                    to: "/crm/configuracoes/interesses",
                },
                {
                    label: "Layouts de Equipamentos",
                    icon: "pi pi-list",
                    to: "/crm/configuracoes/layouts",
                },
                {
                    label: "Motivos de Perda",
                    icon: "pi pi-thumbs-down",
                    to: "/crm/configuracoes/motivo_perda",
                },
                {
                    label: "Preferências",
                    icon: "pi pi-sliders-v",
                    to: "/crm/configuracoes/preferencias",
                },
                {
                    label: "Produtos",
                    icon: "pi pi-shopping-cart",
                    to: "/crm/configuracoes/produtos",
                },

                {
                    label: "Concorrentes",
                    icon: "pi pi-stop",
                    to: "/crm/configuracoes/concorrentes",
                },
            ],
        },

        {
            label: "Administração",
            icon: "pi pi-star",
            items: [
                {
                    label: "Usuários",
                    icon: "pi pi-id-card",
                    to: "/administrador/usuarios",
                },
                {
                    label: "Rotinas",
                    icon: "pi pi-check-square",
                    to: "/administrador/rotinas",
                },
                {
                    label: "Grupos",
                    icon: "pi pi-users",
                    to: "/administrador/grupos",
                },
                {
                    label: "Configurações",
                    icon: "pi pi-sliders-h",
                    items: [
                        {
                            label: "Avatares",
                            icon: "pi pi-users",
                            to: "/configuracoes/avatars",
                        },
                    ],
                },
            ],
        },

        {
            label: "Surpreenda-me",
            icon: "pi pi-box",
            to: "/crm/surpreenda-me",
        },
    ]

    document.addEventListener("DOMContentLoaded", function () {
        if (Notification.permission !== "granted") Notification.requestPermission()
    })

    useEffect(() => {
        window.document.body.classList.remove("apresentation-body-desktop")
        window.document.body.classList.remove("apresentation-body-mobile")
    }, [])

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll()
        } else {
            unblockBodyScroll()
        }
    }, [staticMenuMobileActive])

    useEffect(() => {
        setResetActiveIndex(true)
        setMenuActive(false)

        // Top em background brack
        setTopbarScheme("dark")
        setMenuScheme("dark")
    }, [menuMode])

    useEffect(() => {
        if (localStorage.getItem("@esquadros:user") !== null) {
            dataLocalStorage = JSON.parse(localStorage.getItem("@esquadros:user") || "") || [] || {}

            if (dataLocalStorage) {
                profileUser = dataLocalStorage
                setProfileUser(profileUser)
            }
        }
    }, [])

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setResetActiveIndex(true)
            hideOverlayMenu()
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false)
        }
    }

    const onMenuClick = (event) => {
        if (menuActive && event.target.className === "layout-menu-container") {
            setResetActiveIndex(true)
            setMenuActive(false)
        }
        menuClick = true
    }

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add("blocked-scroll")
        } else {
            document.body.className += " blocked-scroll"
        }
    }

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove("blocked-scroll")
        } else {
            document.body.className = document.body.className.replace(new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"), " ")
        }
    }

    const onMenuButtonClick = (event) => {
        menuClick = true
        setTopbarUserMenuActive(false)
        setRightPanelActive(false)

        if (isMobile()) {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive)
            if (staticMenuMobileActive) {
                blockBodyScroll()
            } else {
                unblockBodyScroll()
            }
        }
        event.preventDefault()
    }

    const isMobile = () => {
        return window.innerWidth <= 991
    }

    const isHorizontal = () => {
        return menuMode === "horizontal"
    }

    const isSlim = () => {
        return menuMode === "slim"
    }

    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false)
        unblockBodyScroll()
    }

    const onRightPanelClick = () => {
        rightPanelClick = true
    }

    const onRightPanelButtonClick = () => {
        setRightPanelActive((prevState) => !prevState)
        rightPanelClick = true
    }

    const onTopbarSearchToggle = () => {
        setSearchActive((prevState) => !prevState)
        searchClick = true
    }

    const onTopbarSearchClick = () => {
        searchClick = true
    }

    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive((prevState) => !prevState)
        topbarUserMenuClick = true
    }

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false)
            searchClick = false
        }

        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false)
            topbarUserMenuClick = false
        }

        if (!rightPanelClick && rightPanelActive) {
            setRightPanelActive(false)
        }

        if (!configClick && configActive) {
            setConfigActive(false)
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true)
                setMenuActive(false)
            }

            if (staticMenuMobileActive) {
                hideOverlayMenu()
            }

            unblockBodyScroll()
        }

        searchClick = false
        topbarUserMenuClick = false
        rightPanelClick = false
        configClick = false
        menuClick = false
    }

    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile())
    }

    const onSidebarMouseLeave = () => {
        setSidebarActive(false)
    }

    const onToggleMenu = (event) => {
        menuClick = true
        setSidebarStatic((prevState) => !prevState)

        event.preventDefault()
    }

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive)
    }

    const layoutClassName = classNames(
        "layout-wrapper",
        {
            "layout-sidebar": menuMode === "sidebar",
            "layout-static": menuMode === "sidebar" && sidebarStatic,
            "layout-horizontal": menuMode === "horizontal",
            "layout-rightpanel-active": rightPanelActive,
            "layout-slim": menuMode === "slim",
            "layout-mobile-active": staticMenuMobileActive,
            "p-input-filled": inputStyle === "filled",
            "p-ripple-disabled": !ripple,
        },
        "layout-menu-" + menuScheme + " layout-topbar-" + topbarScheme
    )

    // const getNotifications = async () => {
    //     let header = {
    //         headers: {
    //             route: "list_profile_notifications",
    //         },
    //     }

    //     try {
    //         const listNotifications = await api.get("notifications/profile", header)

    //         if (listNotifications.data) {
    //             setNotifications(listNotifications.data)
    //         }
    //     } catch (error) {}
    // }

    // useEffect(() => {
    //     setTimeout(() => {
    //         getNotifications()
    //     }, 600)
    // }, [])

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Toast ref={toast} position="bottom-center" />

            <AppTopbar
                topbarScheme={topbarScheme}
                onRightPanelButtonClick={onRightPanelButtonClick}
                searchActive={searchActive}
                onTopbarSearchToggle={onTopbarSearchToggle}
                onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive}
                onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu}
                menuActive={menuActive}
                onRootMenuItemClick={onRootMenuItemClick}
                mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick}
                menuMode={menuMode}
                sidebarStatic={sidebarStatic}
                sidebarActive={sidebarActive}
                onSidebarMouseOver={onSidebarMouseOver}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu}
                onMenuButtonClick={onMenuButtonClick}
                resetActiveIndex={resetActiveIndex}
                onMenuClick={onMenuClick}
                notifications={notifications}
            />

            {/* <AppRightPanel onRightPanelClick={onRightPanelClick} notifications={notifications} getNotifications={getNotifications} /> */}

            <div className="layout-main">
                <div className="layout-content">{children}</div>
            </div>

            <div className="layout-mask modal-in"></div>
        </div>
    )
}

export default App
